import axios from 'axios';
import config from '../config';
import Parse from 'parse';
import passwordGenerator from 'generate-password';
import { log } from './Log';

var SPLASH_MERCHANT_TYPES = {
	SOLE_PROP: 0,
	CORPORATION: 1,
	LLC: 2,
	PARTNERSHIP: 3,
	ASSOCIATION: 4,
	NON_PROFIT: 5,
	GOVERNMENTAL: 6
}

var Splash = class Splash {
	constructor() {
		this.baseURL = config.SPLASH_BASE_URL;
		this.SPLASH_MERCHANT_TYPES = SPLASH_MERCHANT_TYPES;
	}

	transformParams(params) {
		if (!params) {
			return {}
		}

		var loginsModel = {
			username: params.username,
			password: passwordGenerator.generate({length: 10, numbers: true, uppercase: true, excludeSimilarCharacters: true, symbols: true}),
			first: params.first,
			last: params.last,
			email: params.email,
			country: params.country,
			address1: params.address1,
			city: params.city,
			state: params.state,
			zip: params.zip,
			phone: params.phone,
			entities: [{
				name: params.businessName,
				email: params.email,
				phone: params.phone,
				address1: params.address1,
				city: params.city,
				state: params.state,
				zip: params.zip,
				country: 'USA',
				website: params.website || 'http://www.medidatewith.me',
				merchant: {
					ein: params.ein, // Federal tax id number (not required for Sole Proprietorship)
					type: params.merchantType, // see SPLASH_MERCHANT_TYPES
					new: 0,
					status: 1,
					mcc: 7997 ,
					tcVersion: 1,
					memebers: [{
						primary: 1,
						country: 'USA',
						address1: params.address1,
						city: params.city,
						state: params.state,
						zip: params.zip,
						phone: params.phone,
						first: params.first,
						last: params.first,
						ssn: params.ssn,
						dob: params.doc, // Date of birth in YYYYMMDD format
						title: 'Teacher',
						ownership: 10000,
						email: params.email,
					}],
				},
				accounts: [{
					primary: 1,
					account: {
					    method: 8,
					    routing: params.routing,
					    number: params.accountNumber
					}
				}]
			}]
		}

		return loginsModel;
	}

	save(endpoint, params, callback) {
		axios.post(this.baseURL + endpoint, params, {
			headers: {
				APIKEY: config.SPLASH_CLIENT_KEY
			}
		})
		.then(function (res) {
			log(res);
			if (res.data.response.errors.length) {
				let err = res.data.response.errors[0];
				callback(err);
				return;
			}
			callback(null, res.data.response.data);
		})
		.catch(function (error) {
			log(error);
			callback(error);
		});
	}

	update(endpoint, params, callback) {
		axios.put(this.baseURL + endpoint, params, {
			headers: {
				APIKEY: config.SPLASH_CLIENT_KEY
			}
		})
		.then(function (res) {
			log(res);
			if (res.data.response.errors.length) {
				let err = res.data.response.errors[0];
				callback(err);
				return;
			}
			callback(null, res.data.response.data);
		})
		.catch(function (error) {
			log(error);
			callback(error);
		});
	}

	registerTeacher(params, callback) {
		this.save('logins', this.transformParams(params), (err, res) => {
			log(err, res)
			if (err) {
				callback(err);
				return;
			}

			var data = res[0];
			log(data);
			api.User.updateUser({
				splash_seller_id: data.entities[0].merchant.id,
				splash_entity_id: data.entities[0].id,
				splash_account_token: data.entities[0].accounts[0].token
			}, (err, res) => {
				if (err) {
					log(err);
					callback(err);
					return;
				}

				//emailType 1
				callback(err, res);
			});
		})
	}

	createSplashPlan(params, callback) {
		log(params)
		this.save('plans', params, (err, res) => {
			log('createPlan', err, res)
			callback(err, res);
		})
	}

	updateSplashPlan(params, callback) {
		let {schedule, amount} = params;
		this.update('plans/' + params.merchant, {amount}, (err, res) => {
			log('updateSplashPlan', err, res);
			callback(err, res);
		})
	}

	setSplashPlan(plan, params, callback) {
		params.merchant = Parse.User.current().get('splash_seller_id');
		params.schedule = 3;
		log('setSplashPlan', params)
		if (plan.get('splash_plan_id')) {
			this.updateSplashPlan(params, callback);
		} else {
			this.createSplashPlan(params, callback);
		}
	}

	getFunds(callback) {
		if (!config.SPLASH_CLIENT_KEY) {
			setTimeout(() => { this.getFunds(callback) }, 500)
			return;
		}
		axios.get(this.baseURL + 'funds/', {
			headers: {
				APIKEY: config.SPLASH_CLIENT_KEY,
				search: 'entity[equals]=' + Parse.User.current().get('splash_entity_id')
			}
		})
		.then((res) => {
			log(res);
			if (res.data.errors && res.data.errors.length) {
				callback(res.data.errors[0])
				return;
			}

			var data = res.data.response.data.length ? res.data.response.data[0] : {};
			callback(null, data);
		})
		.catch((err) => {
			log(err);
			callback(err);
		});
	}
}

// var Splash = class Splash {
// 	constructor() {
// 		this.baseURL = config.SPLASH_BASE_URL;
// 		this.SPLASH_MERCHANT_TYPES = SPLASH_MERCHANT_TYPES;
// 	}
//
// 	registerTeacher(params, callback) {
// 		log('try registerTeacher from cloud');
// 		params.userId = Parse.User.current().id;
// 		Parse.Cloud.run('splash.registerTeacher', params, {
// 			success: (res) => {
// 				log('registered Teacher from cloud');
// 				Parse.User.current().fetch();
// 				callback(null, res)
// 			},
// 			error: (err) => {
// 				callback(err)
// 			}
// 		})
// 	}
//
// 	createSplashPlan(params, callback) {
// 		log('try createSplashPlan from cloud');
// 		Parse.Cloud.run('splash.createSplashPlan', params, {
// 			success: (res) => {
// 				log('created SplashPlan from cloud');
// 				callback(null, res)
// 			},
// 			error: (err) => {
// 				callback(err)
// 			}
// 		})
// 	}
//
// 	updateSplashPlan(params, callback) {
// 		log('try updateSplashPlan from cloud');
// 		Parse.Cloud.run('splash.updateSplashPlan', params, {
// 			success: (res) => {
// 				log('updated SplashPlan from cloud');
// 				callback(null, res)
// 			},
// 			error: (err) => {
// 				callback(err)
// 			}
// 		})
// 	}
//
// 	setSplashPlan(plan, params: {amount: Number}, callback) {
// 		log('try setSplashPlan from cloud');
// 		params.merchant = Parse.User.current().get('splash_seller_id');
// 		params.schedule = 3;
// 		Parse.Cloud.run('splash.setSplashPlan', params, {
// 			success: (res) => {
// 				log('set SplashPlan from cloud');
// 				callback(null, res)
// 			},
// 			error: (err) => {
// 				callback(err)
// 			}
// 		})
// 	}
//
// 	getFunds(callback) {
// 		log('try getFunds from cloud');
// 		Parse.Cloud.run('splash.getFunds', {}, {
// 			success: (res) => {
// 				log('got Funds from cloud');
// 				callback(null, res)
// 			},
// 			error: (err) => {
// 				callback(err)
// 			}
// 		})
// 	}
// }

export default new Splash();
