import Parse from 'parse';
import BaseModel from './BaseModel';
import _ from 'underscore';
import User from './User';
// import moment from 'moment';
import moment from '../config/momentConfig';
import config from '../config';
import { log } from './Log';

var Product = class Product extends BaseModel {
	constructor() {
		super();
	}

	current() {
		return this.currentProduct;
	}

	currentType() {
		return this.currentProductType;
	}

	setCurrentProduct(product) {
		this.currentProduct = product;
		this.currentProductType = product.className;
	}

	getRelatedUserPaymentProducts(teacherId, callback) {
		var UserPaymentProduct = Parse.Object.extend('UserPaymentProduct');
		var query = new Parse.Query(UserPaymentProduct);

		var owner;
		if (teacherId && teacherId !== Parse.User.current().id) {
			owner = new Parse.User({
				id: teacherId
			});
		} else {
			owner = Parse.User.current();
		}
		query.equalTo('owner', owner);
		query.exists('buyer');
		query.include('ticket');
		query.include('membership');
		query.include('buyer');
		query.include(config.ENV === 'development' ? 'payment_debug' : 'payment');
		query.include('payment.entries_used');
		query.include('payment_debug.entries_used');
		query.include('payment.payment_method_type');
		query.include('payment_debug.payment_method_type');
		query.notEqualTo('is_medidate_membership', true);

		query.find({
			success: (res) => {
				log('success get all products for user', res)

				var results = {
					finishedTicketsWithPunches: [],
					finishedTicketsWithoutPunches: [],
					frozenTickets: [],
					frozenMemberships: [],
					unpaidTickets: [],
					unpaidMemberships: []
				}

				res.forEach((upp) => {
					try {
						if (upp.get('ticket')) {
							if (upp.get('frozen')) {
								results.frozenTickets.push(this.transformStudentPaymentProduct(upp));
							} else if (!this.isUserPaymentProductPaid(upp)) {
								results.unpaidTickets.push(this.transformStudentPaymentProduct(upp));
							} else if (this.isUserPaymentProductDateEnded(upp) &&
								!this.isUserPaymentProductPunchesEnded(upp)) {
								results.finishedTicketsWithPunches.push(this.transformStudentPaymentProduct(upp));
							} else if (this.isUserPaymentProductDateEnded(upp) &&
								this.isUserPaymentProductPunchesEnded(upp)) {
								results.finishedTicketsWithoutPunches.push(this.transformStudentPaymentProduct(upp));
							}
						} else if (upp.get('membership')) {
							if (upp.get('frozen')) {
								results.frozenMemberships.push(this.transformStudentPaymentProduct(upp));
							} else if (!this.isUserPaymentProductPaid(upp)) {
								results.unpaidMemberships.push(this.transformStudentPaymentProduct(upp));
							}
						}
					} catch (e) {
						log(e)
					}
				});
				callback(null, results)
			},
			error: function (error) {
				callback(error, null)
			}
		});
	}

	getProducts(productType, callback) {
		var Product = Parse.Object.extend(productType);
		var query = new Parse.Query(Product);

		query.equalTo('owner', Parse.User.current())
		query.ascending('date')
		query.exists('title')

		query.find({
			success: (results) => {
				log('success')
				callback(null, results)
			},
			error: function (error) {
				callback(error, null)
			}
		});
	}

	isProductHasStudents(product, callback) {
		this.setCurrentProduct(product);
		this.getProductStudents((err, res) => {
			if (err) {
				callback(err);
			}
			return callback(null, !!res.length);
		})
	}

	isCurrentProductHasStudents(callback) {
		this.getProductStudents((err, res) => {
			if (err) {
				callback(err);
			}
			return callback(null, !!res.length);
		})
	}

	removeProduct(product, callback) {
		this.isProductHasStudents(product, (err, res) => {
			if (res === false) {
				product.destroy({
					success: () => {
						callback(null);
					},
					error: (err) => {
						callback(err);
					}
				});
			} else {
				err = {
					message: "Can't remove product with registered students"
				}
				callback(err)
			}
		})
	}

	toggleProductActiveState(product, callback) {
		this.updateProduct(product, { active: !product.get('active') }, (err, res) => {
			callback(err, res);
		});
	}

	getProductById(productType, productId, teacherId, callback) {
		log('getProductById')
		log('getProductById productType', productType)
		log('getProductById productId', productId)
		log('getProductById teacherId', teacherId)
		var Product = Parse.Object.extend(productType);
		var query = new Parse.Query(Product);

		query.equalTo('owner', teacherId ? Parse.User.createWithoutData(teacherId) : Parse.User.current())
		query.equalTo('objectId', productId)
		query.include(config.ENV === 'development' ? 'payment_debug' : 'payment');

		query.find({
			success: (results) => {
				log('success', results)
				var product = results.length ? results[0] : {};
				this.setCurrentProduct(product)
				callback(null, product)
			},
			error: function (error) {
				callback(error, null)
			}
		});
	}

	addNewProduct(productType, callback) {
		if (!productType) {
			return
		}

		var Product = Parse.Object.extend(productType);
		var product = new Product();

		product.save({
			owner: Parse.User.current(),
			active: true,
			currency: User.getCurrencyByBankCountry(Parse.User.current())
		}, {
			success: function (res) {
				log('product create success', res)
				callback(null, res);
			},
			error: function (error) {
				log('Failed to create object, with error code: ' + error.message);
				callback(error);
			}
		});
	}

	addNewProductWithParams(productType, params, callback) {
		if (!productType) {
			return
		}

		log('addNewProductWithParams', productType)
		log('addNewProductWithParams', params)
		var Product = Parse.Object.extend(productType);
		var product = new Product();
		//TODO add params of product while Saving

		product.save(params, {
			success: function (res) {
				log('product with params create success', res)
				callback(null, res);
			},
			error: function (error) {
				log('Failed to create object, with error code: ' + error.message);
				callback(error);
			}
		});
	}

	updateProduct(product, props, callback) {
		if (!product) {
			return;
		}

		log('updateproduct', props);

		product.save(props, {
			success: function (product) {
				log('product update success')
				callback(null, product);
			},
			error: function (error) {
				log('Failed to update object, with error code: ' + error.message);
				callback(error);
			}
		});
	}

	updateUserPaymentProduct(userPaymentProduct, props, callback) {
		if (!userPaymentProduct) {
			return;
		}

		userPaymentProduct.save(props, {
			success: function (userPaymentProduct) {
				log('UserPaymentProduct update success', userPaymentProduct)
				callback(null, userPaymentProduct);
			},
			error: function (error) {
				log('Failed to update object, with error code: ' + error.message);
				callback(error);
			}
		});
	}

	updateCurrentProduct(props, callback) {
		log('update', props, this.current())
		if (!this.current()) {
			return;
		}

		this.current().save(props, {
			success: function (session) {
				log('product update success')
				callback(null, session);
			},
			error: function (error) {
				log(error)
				log('Failed to update object, with error code: ' + error.message);
				callback(error);
			}
		});
	}

	getProductsSessionRelationsForProduct(sessions, callback) {
		var ProductSessionRelation = Parse.Object.extend('ProductSessionRelation');

		var Session = Parse.Object.extend('MSession');
		var sessionsQuery = new Parse.Query(Session);
		sessionsQuery.greaterThanOrEqualTo('date', new Date());
		sessionsQuery.notEqualTo('canceled', true);

		var query = new Parse.Query(ProductSessionRelation);

		query.equalTo('product', this.current());
		query.include('ticket');
		query.include('membership');
		query.include(config.ENV === 'development' ? 'payment_debug' : 'payment');

		if (sessions) {
			query.containedIn('session', sessions);
		}

		query.matchesQuery('session', sessionsQuery);

		query.find({
			success: (results) => {
				log('success')
				callback(null, results)
			},
			error: function (error) {
				callback(error, null)
			}
		});
	}

	removeSessionFromProduct(session, callback) {
		if (!session) {
			return;
		}

		var ProductSessionRelation = Parse.Object.extend('ProductSessionRelation');
		var query = new Parse.Query(ProductSessionRelation);
		query.equalTo('product', this.current());
		query.equalTo('session', session);

		query.find({
			success: (results) => {
				log('success find')
				results.map((productSessionRelation) => {
					productSessionRelation.destroy({
						success: function (res) {
							log('success destroy', res)
							callback(null, res);
						},
						error: function (error) {
							log('Failed to destroy object, with error code: ' + error.message);
							callback(error);
						}
					});
				})
			},
			error: function (error) {
				callback(error, null)
			}
		});
	}

	addSessionToProduct(session, callback) {
		log('add to product')
		if (!session) {
			return;
		}

		var ProductSessionRelation = Parse.Object.extend('ProductSessionRelation');
		var productSessionRelation = new ProductSessionRelation();

		productSessionRelation.save({
			product: this.current(),
			session
		}, {
			success: function (res) {
				log('productSessionRelation create success', res)
				callback(null, res);
			},
			error: function (error) {
				log('Failed to create object, with error code: ' + error.message);
				callback(error);
			}
		});
	}

	addSessionsToProduct(sessions, callback) {
		log('add multiple to product')
		if (!sessions || !sessions.length) {
			log('no sessions')
			callback(undefined);
		}

		try {
			var productSessionRelations = [];
			var ProductSessionRelation = Parse.Object.extend('ProductSessionRelation');
			for (var i = 0; i < sessions.length; i++) {
				var productSessionRelation = new ProductSessionRelation();
				productSessionRelation.set('product', this.current())
				productSessionRelation.set("session", sessions[i])
				productSessionRelations.push(productSessionRelation)
			}

			log('save productSessionRelations', productSessionRelations.length)
			Parse.Object.saveAll(productSessionRelations, {
				success: function (res) {
					log('productSessionRelations create success', res)
					callback(null, res);
				},
				error: function (error) {
					log('Failed to create object, with error code: ' + error.message);
					callback(error);
				},
			});
		} catch (e) {
			log(e);
		}
	}

	transformStudentPaymentProduct(upp) {
		try {
			log('upp', upp);
			var student = upp.get('buyer');

			if (!student) {
				return false;
			}

			student.userPaymentProduct = upp;
			student.payment = upp.get(config.ENV === 'development' ? 'payment_debug' : 'payment');

			return student;
		} catch (e) {
			log(e);
			return false;
		}
	}

	getUserPaymentProductEntriesLeft(upp) {
		var product = upp.get('ticket') || upp.get('membership');
		return product.get('entries_number') - (upp.get('punched_count') || 0);
	}

	getProductEntriesNumber(upp) {
		if (upp.get('ticket')) {
			var product = upp.get('ticket');
			return product.get('entries_number');
		} else return 0;
	}

	isUserPaymentProductDateEnded(upp) {
		var product = upp.get('ticket') || upp.get('membership');
		// return moment(upp.get('expiration_date')).isAfter(new Date());
		return moment(new Date()).isAfter(upp.get('expiration_date').setHours(23, 59, 59, 0));
	}

	isUserPaymentProductPunchesEnded(upp) {
		var product = upp.get('ticket');
		return this.getUserPaymentProductEntriesLeft(upp) <= 0;
	}

	isUserPaymentProductPaid(upp) {
		return upp.get(config.ENV === 'development' ? 'payment_debug' : 'payment');
	}

	isUserPaymentProductValid(upp) {
		try {
			var product = upp.get('ticket') || upp.get('membership');

			if (upp.get('ticket')) {
				// log("check ticket", upp)
				return moment(upp.get('expiration_date').setHours(23, 59, 59, 0)).isAfter(new Date()) && this.getUserPaymentProductEntriesLeft(upp) > 0;
			} else {
				// log("check membership", upp)
				return moment(upp.get('expiration_date').setHours(23, 59, 59, 0)).isAfter(new Date());
			}
		} catch (e) {
			log(e)
			return false
		}
	}

	getStudentProduct(student, teacherId, callback) {
		var UserPaymentProduct = Parse.Object.extend('UserPaymentProduct');
		var query = new Parse.Query(UserPaymentProduct);

		var owner;
		if (teacherId && teacherId !== Parse.User.current().id) {
			owner = new Parse.User({
				id: teacherId
			});
		} else {
			owner = Parse.User.current();
		}
		query.equalTo('buyer', student);
		query.equalTo('owner', owner);
		query.include('ticket');
		query.include('membership');
		query.notEqualTo('is_medidate_membership', true);
		query.include(config.ENV === 'development' ? 'payment_debug' : 'payment');
		query.descending('createdAt');

		query.find({
			success: (res) => {
				log('success get products for student', res)
				var results = {
					tickets: [],
					memberships: [],
					frozenTickets: [],
					frozenMemberships: [],
					finishedTickets: [],
					finishedMemberships: []
				}
				res.forEach((upp) => {
					if (upp.get('ticket')) {
						if (this.isUserPaymentProductValid(upp)) {
							if (upp.get('frozen')) {
								results.frozenTickets.push(upp);
							} else {
								results.tickets.push(upp);
							}
						} else {
							results.finishedTickets.push(upp);
						}
					} else if (upp.get('membership')) {
						if (this.isUserPaymentProductValid(upp)) {
							if (upp.get('frozen')) {
								results.frozenMemberships.push(upp);
							} else {
								results.memberships.push(upp);
							}
						} else {
							results.finishedMemberships.push(upp);
						}
					}
				});

				callback(null, results)
			},
			error: function (error) {
				callback(error, null)
			}
		});
	}

	getProductStudents(callback, limit) {
		var UserPaymentProduct = Parse.Object.extend('UserPaymentProduct');
		var query = new Parse.Query(UserPaymentProduct);
		query.equalTo('product', this.current());
		query.include('buyer');
		query.include(config.ENV === 'development' ? 'payment_debug' : 'payment');
		query.include('payment.entries_used');
		query.include('payment_debug.entries_used');
		query.include('payment.payment_method_type');
		query.include('payment_debug.payment_method_type');
		query.include('payment.coupon');
		query.include('payment_debug.coupon');
		query.limit(limit ? limit : 500);
		query.descending('updatedAt');
		query.find({
			success: (res) => {
				log('success get product students', res)
				var results = _.without(res.map((upp) => {
					return this.transformStudentPaymentProduct(upp);
				}), false);
				log(results)
				callback(null, results)
			},
			error: function (error) {
				callback(error, null)
			}
		});
	}

	getSingleProductStudent(student, callback) {
		var UserPaymentProduct = Parse.Object.extend('UserPaymentProduct');
		var query = new Parse.Query(UserPaymentProduct);
		query.equalTo("buyer", student);
		query.equalTo('product', this.current());
		query.include('buyer');
		query.include(config.ENV === 'development' ? 'payment_debug' : 'payment');
		query.include('payment.entries_used');
		query.include('payment_debug.entries_used');
		query.include('payment.payment_method_type');
		query.include('payment_debug.payment_method_type');
		query.notEqualTo('is_medidate_membership', true);
		query.descending('expiration_date');

		query.find({
			success: (userPaymentProducts) => {
				log('success get single product for student', JSON.stringify(userPaymentProducts[0]))
				var result = this.transformStudentPaymentProduct(userPaymentProducts[0]);
				log(result)
				callback(null, result)
			},
			error: function (error) {
				callback(error, null)
			}
		});
	}

	getFilterProductStudents(filteredStudents, callback) {
		var UserPaymentProduct = Parse.Object.extend('UserPaymentProduct');
		var query = new Parse.Query(UserPaymentProduct);
		query.equalTo('product', this.current());
		query.containedIn('buyer', filteredStudents)
		query.include('buyer');
		query.include(config.ENV === 'development' ? 'payment_debug' : 'payment');
		query.include('payment.entries_used');
		query.include('payment_debug.entries_used');
		query.include('payment.payment_method_type');
		query.include('payment_debug.payment_method_type');
		query.include('payment.coupon');
		query.include('payment_debug.coupon');
		query.notEqualTo('is_medidate_membership', true);

		query.find({
			success: (res) => {
				log('success get product students', res)
				var results = _.without(res.map((upp) => {
					return this.transformStudentPaymentProduct(upp);
				}), false);
				log(results)
				callback(null, results)
			},
			error: function (error) {
				callback(error, null)
			}
		});
	}

	addStudentToProduct(product, student, callback) {
		
		log('addStudentToProduct', product)
		log('addStudentToProduct', student)
		var UserPaymentProduct = Parse.Object.extend('UserPaymentProduct');
		var userPaymentProduct = new UserPaymentProduct();

		var studentPointer = new Parse.User({
			id: student.id
		});

		var ownerPointer = new Parse.User({
			id: Parse.User.current().id
		});

		log('studentPointer', studentPointer)
		userPaymentProduct.save({
			product,
			buyer: studentPointer,
			owner: ownerPointer
		}, {
			success: (res) => {
				log('userPaymentProduct create success', res);
				var data = this.transformStudentPaymentProduct(res);
				callback(null, data);
			},
			error: function (error) {
				log('Failed to create object', error);
				callback(error);
			}
		});
	}

	removeStudentFromProduct(student, callback) {
		var UserPaymentProduct = Parse.Object.extend('UserPaymentProduct');
		var query = new Parse.Query(UserPaymentProduct);
		query.equalTo('product', this.current());
		query.equalTo('buyer', student);

		query.find({
			success: (results) => {
				log('success find')
				results.map((userPaymentProduct) => {
					userPaymentProduct.destroy({
						success: function (res) {
							log('success destroy', res)
							callback(null, res);
						},
						error: function (error) {
							log('Failed to destroy object, with error code: ' + error.message);
							callback(error);
						}
					});
				})
			},
			error: function (error) {
				callback(error, null)
			}
		});
	}

	addStudentToCurrentProduct(student, callback) {
		var Product = Parse.Object.extend(this.current().className);
		var product = new Product();
		this.current().increment("purchased_count");
		this.current().save({}, {
			success: (product) => {
				this.addStudentToProduct(this.current(), student, callback);
			},
			error: function (error) {
				this.addStudentToProduct(this.current(), student, callback);
			}
		})
	}

	removeStudentfromCurrentProduct(userPaymentProduct, callback) {
		var params = {isProduct: true, uppId: userPaymentProduct.id};
		log('removeStudentfromCurrentProduct params', params);
		Parse.Cloud.run('cancelProduct', params, {
			success: res => {
				log(res)
				log('Cancelled Successfully', res)
				callback(null, res);
			},
			error: error => {
				log('Failed to cancel UserPaymentPlan or Product, with error code: ' + error);
				callback(error);
			}
		});
		// this.current().increment("purchased_count", -1);
		// this.current().save();
		// userPaymentProduct.destroy({
		// 	success: function (res) {
		// 		log('success destroy', res)
		// 		callback(null, res);
		// 	},
		// 	error: function (error) {
		// 		log('Failed to destroy object, with error code: ' + error.message);
		// 		callback(error);
		// 	}
		// });
	}

	freezeUserPaymentProduct(weeksNumber, userPaymentProduct, callback) {
		log('freezeUserPaymentProduct')
		if (!userPaymentProduct) {
			return;
		}

		var freezeDate = new Date();
		userPaymentProduct.set("freeze_date", freezeDate);
		userPaymentProduct.set("weeks_to_unfreeze", weeksNumber);
		userPaymentProduct.set("unfreeze_date", this.getAutoUnfreezeUserPaymentProductDate(userPaymentProduct));
		userPaymentProduct.set("frozen", true);

		userPaymentProduct.save({
			success: function (userPaymentProduct) {
				log('UserPaymentProduct freeze success', userPaymentProduct)
				callback(null, userPaymentProduct);
			},
			error: function (error) {
				log('Failed to freeze UserPaymentProduct', error);
				callback(error);
			}
		});
	}

	unFreezeUserPaymentProduct(userPaymentProduct, callback) {
		log('unFreezeUserPaymentProduct')
		if (!userPaymentProduct) {
			return;
		}

		userPaymentProduct.set("expiration_date", this.getFrozenUserPaymentProductExpiration(userPaymentProduct));
		userPaymentProduct.unset("weeks_to_unfreeze");
		userPaymentProduct.set("frozen", false);
		userPaymentProduct.unset("freeze_date");
		userPaymentProduct.unset("unfreeze_date");
		userPaymentProduct.save({}, {
			success: function (userPaymentProduct) {
				log('UserPaymentProduct unfrozen success', userPaymentProduct)
				callback(null, userPaymentProduct);
			},
			error: function (error) {
				log('Failed to unfreeze UserPaymentProduct', error);
				callback(error);
			}
		});
	}

	getFrozenUserPaymentProductExpiration(userPaymentProduct) {
		log('getFrozenUserPaymentProductExpiration')
		if (!userPaymentProduct) {
			return;
		}

		var freezeDate = moment(userPaymentProduct.get("freeze_date"));
		var now = moment(new Date());
		var daysDifference = moment.duration(now.diff(freezeDate)).asDays();
		daysDifference = parseInt(Math.abs(daysDifference));
		log('daysDifference', daysDifference)

		var expirationDate = moment(userPaymentProduct.get("expiration_date"));
		log('expirationDate', expirationDate)

		var newExpirationDate = expirationDate.add(daysDifference, 'days').toDate();
		log('newExpirationDate', newExpirationDate)

		return newExpirationDate;
	}

	getAutoUnfreezeUserPaymentProductDate(userPaymentProduct) {
		log('getAutoUnfreezeUserPaymentProductDate')
		if (!userPaymentProduct) {
			return;
		}

		var freezeDate = moment(userPaymentProduct.get("freeze_date"));
		var autoUnfreezeWeeks = userPaymentProduct.get("weeks_to_unfreeze")


		var unfreezeDate = freezeDate.add(autoUnfreezeWeeks, 'weeks').toDate();
		log('unfreezeDate', unfreezeDate)

		return unfreezeDate;
	}
}

export default new Product();
