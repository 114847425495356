import Parse from 'parse';
import BaseModel from './BaseModel';
import _ from 'underscore';
import {I18n} from 'react-i18nify';
import { log } from '../api/Log'

var Subcategories = class Subcategories extends BaseModel {

	constructor() {
		super();
		this.yoga_objects = [];
		this.meditation_objects = [];
	}

	getObjectsByIds(ids) {
		var objects = this.yoga_objects.concat(this.meditation_objects);

		return _.filter(objects, function(item) {
    		return ids.indexOf(item.id) !== -1;
		});
	}

	getLabelKey() {
		return I18n._localeKey === 'he' ? 'value_he' : 'value_en';
	}

	parseSubCatsToSelect(subcats) {
	    return subcats.map((subcat) => {
	      return {
	        label: subcat.get(this.getLabelKey()),
	        value: subcat.id
	      }
	    })
  	}

  	parseSubCatsToIdValues(subcats) {
	    return subcats.map((subcat) => {
	      return subcat.id
	    })
  	}

	getSubCatByMainCat(mainCat, callback) {
		log('getSubCatByMainCat');
		var Subcategories = Parse.Object.extend('Subcategory');
		var query = new Parse.Query(Subcategories);
		query.equalTo("main_category", Number(mainCat));
		query.find({
		  success: (results) => {
			  try {
				log('getSubCatByMainCat', results);
				  if (mainCat == 1) {
					  this.yoga_objects = results;
				  } else if (mainCat == 0) {
					  this.meditation_objects = results;
				  }
				  callback(null, results)
			  } catch (error) {
				log('error', error);
			  }
		  },
		  error: function(error) {
		  	callback(error, null)
		  }
		});
	}
}

export default new Subcategories();
