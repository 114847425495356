import Parse from 'parse';
import BaseModel from './BaseModel';
import Noti from './Noti';
import _ from 'underscore';
import { log } from './Log'

var Studio = class Studio extends BaseModel {
  constructor() {
    super();
    this.associated_teachers = [];
  }

  current() {
    return this.currentUserStudio;
  }

  getCurrentStudioAttr() {
    if (!this.current()) {
      return;
    }

    return this.current().attributes
  }

  getUserStudio(callback) {
    log('getUserStudio')
    return new Promise((resolve, reject) => {  
      var Studio = Parse.Object.extend('Studio');
      var query = new Parse.Query(Studio);
      query.equalTo('owner', Parse.User.current())
      query.find({
      success: (results) => {
          log('getUserStudio?', results)
          this.currentUserStudio = results[0];
          resolve(results[0])
          if (callback) {
            callback(null, results[0])
          }
        },
        error: function (error) {
          reject(error)
          if (callback) {
            callback(error, null)
          }
        }
      });
    }); 
  }

  createUserStudio(params) {
    log('creating studio');
    var Studio = Parse.Object.extend('Studio');
    var userStudio = new Studio();

    params = {...params,owner: Parse.User.current()};


    userStudio.save(params, {
        success: (studio) => {
          this.currentUserStudio = studio;
          Parse.User.current().set('studio',studio);
          Parse.User.current().set('is_studio',true);
          Parse.User.current().save();
          callback(null, studio);
        },
        error: function (error) {
          callback(error, null)
        }
      });
  }

  updateStudio(props) {
    if (!this.current()) {
      return;
    }
    this.current().save(props, {
      success: function (studio) {
        currentUser.fetch();
        log('studio update success')
        callback(null, studio);
      },
      error: function (error) {
        log('Failed to update object, with error code: ' + error.message);
        callback(error, null);
      }
    });
  }

  addAssociatedTeacher(teacherObject, callback) {
    log('addAssociatedTeacher to: ' + this.current());
    if (!this.current()) {
      log('addAssociatedTeacher no current');
      return;
    }

    var studio = this.current();
    var associatedTeachersRelation = studio.relation("associated_teachers");
    associatedTeachersRelation.add(teacherObject);

    studio.save(null, {
      success: (res) => {
        this.associated_teachers = [...this.associated_teachers, teacherObject];

        Noti.sendStudioAddedTeacherPushNotification(studio, teacherObject);
        callback(null, res);
      },
      error: (err) => {
        callback(err, null);
        log('error', err)
      }
    });
  }

  removeAssociatedTeacher(teacherObject, callback) {
    if (!this.current()) {
      return;
    }
    var studio = this.current();
    var associatedTeachersRelation = studio.relation("associated_teachers");
    associatedTeachersRelation.remove(teacherObject);
    studio.save(null, {
      success: (res) => {
        this.associated_teachers = _.filter(this.associated_teachers, (item) => item.id !== teacherObject.id)
        callback(null, res);
      },
      error: (err) => {
        callback(err, null);
        log('error', err)
      }
    });
  }

  getCachedAssociatedTeachers() {
    return this.associated_teachers;
  }

  getAssociatedTeachers(callback) {
    log('getAssociatedTeachers', this.current())
    if (!this.current()) {
      return;
    }
    var studio = this.current();
    var queryAssociatedTeachersRelation = studio.relation("associated_teachers").query();
    queryAssociatedTeachersRelation.find({
      success: function (results) {
        log('teachers', results)
        this.associated_teachers = results;
        callback(null, results)
      },
      error: function (error) {
        callback(error, null)
        log(error)
      }
    });
  }
}

export default new Studio();
