// Naming is shorten because Notification is a reserved name in Javascript
import Parse from 'parse';
import BaseModel from './BaseModel';
import _ from 'underscore';
import Noti from './Noti';
// import moment from 'moment';
import moment from '../config/momentConfig';
import { log } from './Log';

var subscription;

var Messages = class Messages extends BaseModel {
  constructor() {
    super();
  }

  getPrivateChatByRecipient(recipient, callback) {
    if (!this.privateChats || !recipient) {
      return;
    }

    var privateChatIndex = _.findIndex(this.privateChats, (chat) => {
      return chat.get('user1').id === recipient.id || chat.get('user2').id === recipient.id;
    });

    if (privateChatIndex !== -1) {
      callback(null, privateChatIndex);
      return;
    }

    this.createPrivateChatByRecipient(recipient, 0);
  }

  createPrivateChatByRecipient(recipient, callback) {
    var PrivateChat = Parse.Object.extend('PrivateChat');
    var privateChat = new PrivateChat();
    privateChat.save({ user1: Parse.User.current(), user2: recipient }, {
      success: function (res) {
        callback(null, res);
      },
      error: function (error) {
        log('Failed to create object, with error code: ' + error.message);
        callback(error);
      }
    })
  }

  getPrivateChatsMessages(privateChat, callback) {
    if (!privateChat) {
      callback(null, []);
      return;
    }
    var messagesRelation = privateChat.relation('messages').query();
    messagesRelation.ascending('createdAt');

    messagesRelation.find({
      success: function (results) {
        callback(null, results)
      },
      error: function (error) {
        callback(error, null)
      }
    });
  }

  getPrivateChatById(id, callback) {
    var PrivateChat = Parse.Object.extend('PrivateChat');
    var query = new Parse.Query(PrivateChat);
    query.equalTo('objectId', id);
    query.include('user1');
    query.include('user2');

    query.find({
      success: (results) => {
        callback(null, results[0])
      },
      error: function (error) {
        callback(error, null)
      }
    })
  }

  getPrivateChats(callback) {
    var PrivateChat = Parse.Object.extend('PrivateChat');
    var query1 = new Parse.Query(PrivateChat);
    var query2 = new Parse.Query(PrivateChat);

    query1.equalTo('user1', Parse.User.current());
    query2.equalTo('user2', Parse.User.current());
    var query = Parse.Query.or(query1, query2);
    query.include('user1');
    query.include('user2');
    query.include('messages');
    query.descending('updatedAt');

    this.unsubscribeToChatChanges();

    subscription = ParseLiveQueryClient.subscribe(query);

    subscription.on('update', (object) => {
      log('chat update');
      this.privateChats.map((chat) => {
        if (chat.id === object.id) {
          return object;
        }
        return chat;
      })
      callback(null, this.privateChats)
    });

    subscription.on('create', (object) => {
      log('chat create');
      if (!_.isEmpty(object.get('user1').attributes) && !_.isEmpty(object.get('user2').attributes)) {
        if (!this.privateChats.some((pc) => pc.id === object.id)) {
          this.privateChats.unshift(object)
        }
        callback(null, this.privateChats)
        return
      }

      this.getPrivateChatById(object.id, (err, res) => {
        if (err) {
          return;
        }

        this.privateChats.unshift(res)
        callback(null, this.privateChats)
      })
    });

    query.find({
      success: (results) => {
        this.privateChats = results;
        callback(null, results)
      },
      error: function (error) {
        callback(error, null)
      }
    });
  }

  getPrivateChatRecipient(chat) {
    if (!chat) {
      log("No chat object");
      return;
    }

    if (!chat.get('user1') || !chat.get('user2')) {
      return;
    }

    if (chat.get('user1').id === Parse.User.current().id) {
      return chat.get('user2')
    } else {
      return chat.get('user1')
    }
  }

  sendMessage(props, callback) {
    var { text, relationParentObject } = props;
    var Message = Parse.Object.extend('Message');
    var message = new Message();

    var pushType;
    if (relationParentObject.className === "PrivateChat") {
      //Private Chat
      pushType = Noti.NOTIFICATIONS_TYPES.PushTypePrivateMessage;
      message.set("private_chat", relationParentObject);
    } else if (relationParentObject.className === Parse.User.className) {
      //Followers Chat
      pushType = Noti.NOTIFICATIONS_TYPES.PushTypeFollowersMessage;
      message.set("teacher", relationParentObject);
    } else if (relationParentObject.className === "MSession") {
      //Session Chat
      pushType = Noti.NOTIFICATIONS_TYPES.PushTypeMessage;
      message.set("session", relationParentObject);
    } else if (relationParentObject.className === "Studio") {
      //Studio Chat
      pushType = Noti.NOTIFICATIONS_TYPES.PushTypeStudioMessage;
      message.set("studio", relationParentObject);
    }

    message.save({ user: Parse.User.current(), text }, {
      success: function (res) {
        var messagesRelation = relationParentObject.relation('messages');
        messagesRelation.add(res);
        relationParentObject.save(null);

        Noti.sendChatPushNotification({
          relationParentObject: relationParentObject,
          pushType: pushType,
          messageId: res.id,
          messageText: text
        });
      },
      error: function (error) {
        log('Failed to create object, with error code: ');
      }
    });
  }

  getAllStudentsMessages(callback) {
    var messagesRelationQuery = Parse.User.current().relation('messages').query();
    messagesRelationQuery.include('user');
    messagesRelationQuery.ascending('createdAt');

    var queryToSubscribe = new Parse.Query(Parse.Object.extend('User'));
    queryToSubscribe.include('messages');
    queryToSubscribe.equalTo('objectId', Parse.User.current().id);

    this.unsubscribeToChatChanges();
    
    subscription = ParseLiveQueryClient.subscribe(queryToSubscribe);

    var findFunction = () => {
      messagesRelationQuery.find({
        success: (results) => {
          this.allStudentsMessages = results;
          callback(null, results)
        },
        error: function (error) {
          callback(error, null)
        }
      })
    }

    subscription.on('update', (object) => {
      findFunction();
    });

    findFunction();
  }

  getStudioMessages(studio, callback) {
    if (!studio) {
      return;
    }

    var messagesRelation = studio.relation('messages').query();
    messagesRelation.ascending('createdAt');
    messagesRelation.include('user');

    messagesRelation.find({
      success: function (results) {
        callback(null, results)
      },
      error: function (error) {
        callback(error, null)
      }
    });

  }

  getStudioChats(callback) {
    var Studio = Parse.Object.extend('Studio');
    var query1 = new Parse.Query(Studio);
    var query2 = new Parse.Query(Studio);
    var userToCheck = new Parse.User({
      id: Parse.User.current().id
    });
    query1.equalTo("associated_teachers", userToCheck);
    query2.equalTo("owner", userToCheck);
    var query = Parse.Query.or(query1, query2);

    this.unsubscribeToChatChanges();
    
    subscription = ParseLiveQueryClient.subscribe(query);

    query.find({
      success: (res) => {
        this.studios = res;
        this.studios.map((studio) => {
          var Studio = Parse.Object.extend('Studio');
          var query = new Parse.Query(Studio);
          query.equalTo('objectId', studio.id)
          
          subscription = ParseLiveQueryClient.subscribe(query);
          subscription.on('update', (object) => {
            this.studios.map((chat) => {
              if (chat.id === object.id) {
                return object;
              }
              return chat;
            })
            callback(null, this.studios)
          });
        })

        callback(null, res);
      },
      error: (error) => {
        callback(error);
      }
    });
  }

  getSessionMessages(session, callback) {
    if (!session) {
      return;
    }
    var messagesRelation = session.relation('messages').query();
    messagesRelation.ascending('createdAt');
    messagesRelation.include('user');

    messagesRelation.find({
      success: function (results) {
        callback(null, results)
      },
      error: function (error) {
        callback(error, null)
      }
    });
  }

  getSessionChats(callback) {
    var Session = Parse.Object.extend('MSession');
    var query = new Parse.Query(Session);
    query.equalTo('session_creator', Parse.User.current())
    query.ascending('date');
    query.greaterThanOrEqualTo('date', new Date(moment().subtract(4, 'hours')));
    query.limit(100);
    query.exists("title");

    this.unsubscribeToChatChanges();
    
    subscription = ParseLiveQueryClient.subscribe(query);

    subscription.on('update', (object) => {
      this.sessions.map((chat) => {
        if (chat.id === object.id) {
          return object;
        }
        return chat;
      })
      callback(null, this.sessions)
    });

    query.find({
      success: (results) => {
        callback(null, results)
        this.sessions = results;
      },
      error: function (error) {
        callback(error, null)
      }
    });
  }

  unsubscribeToChatChanges() {
    try {
      ParseLiveQueryClient.unsubscribe(subscription);
    } catch (error) {
      log("unsubscribeToChatChanges", error);
    }
  }
}

export default new Messages();
