
import config from '../config';

export function log(message, secondMessage) {
  // console.log('config.ENV',config.ENV);
  if (config.ENV === 'development') {
    if(secondMessage){
      console.log(message, secondMessage);
    } else{
      console.log(message);
    }
  }else{
    console.log = function() {};
  }
}
