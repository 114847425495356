// Naming is shorten because Notification is a reserved name in Javascript
import Parse from 'parse';
import BaseModel from './BaseModel';
import User from './User';
import Plan from './Plan';
import _ from 'underscore';
import config from '../config';
import countries from 'country-list';
import { log } from './Log';
import Product from './Product';

const paymentTypes = {
	CASH: 0,
	CHECK: 1,
	BANK_TRANSFER: 2,
	OTHER: 3,
	CREDIT_CARD: 4,
	TICKET: 5,
	MEMBERSHIP: 6,
	TRIAL: 7,
	PARTIAL: 8,
	PAYMENTS: 9,
	AUTO_PAYMENTS: 10,
	PAYMENTS_DONE: 11,
	BIT: 12
};

const paymentTypesRev = {
	0: 'CASH',
	1: 'CHECK',
	2: 'BANK_TRANSFER',
	3: 'OTHER',
	4: 'CREDIT_CARD',
	5: 'TICKET',
	6: 'MEMBERSHIP',
	7: 'TRIAL',
	8: 'PARTIAL',
	9: 'PAYMENTS',
	10: 'AUTO_PAYMENTS',
	11: 'PAYMENTS_DONE',
	12: 'BIT'
};

const paymentPaidTypes = {
	CASH: 0,
	CHECK: 1,
	BANK_TRANSFER: 2,
	OTHER: 3
};

const currencyTypes = {
	0: 'ILS',
	1: 'USD',
	2: 'EUR'
};

var Payment = class Payment extends BaseModel {
	constructor() {
		super();
		this.paymentTypes = paymentTypes;
		this.paymentTypesRev = paymentTypesRev;
		this.paymentPaidTypes = paymentPaidTypes;
		this.currencyTypes = currencyTypes;
		this.paymentClassName = config.ENV === 'development' ? 'PaymentDebug' : 'Payment';
		this.paymentFieldName = config.ENV === 'development' ? 'payment_debug' : 'payment';
	}

	getPaymentByStudentAndProduct(props, callback) {
		var { student, ticket, session, membership, product } = props;
		log('getPaymentByStudentAndProduct', props);
		var Payment = Parse.Object.extend(this.paymentClassName);
		var query = new Parse.Query(Payment);

		query.equalTo('buyer', student);
		query.equalTo('session', session);
		query.equalTo('membership', membership);
		query.equalTo('ticket', ticket);
		query.equalTo('teacher', Parse.User.current());
		query.equalTo('product', product);


		query.find({
			success: (res) => {
				callback(null, res);
			},
			error: (err) => {
				callback(err);
			}
		})

	}

	// addPaidTypePayment(params: {student: Object, price: Number, session: Object, plan: Object,product: Object, payment: Object, received_by: Object}, callback) {
	addPaidTypePayment(params, callback) {
		log('addPaidTypePayment');
		try {
			var tempPayment = params.payment;

			if (!tempPayment) {
				var Payment = Parse.Object.extend(this.paymentClassName);
				tempPayment = new Payment();
				log('payment', "new payment");
			} else {
				log('payment', params.payment.id);
			}

			log('partial_amount', params.partial_amount);

			tempPayment.save({
				payment_method_type: params.payment_method_type,
				payment_method_extra_details: params.payment_method_extra_details,
				payment_method_extra_type: params.payment_method_extra_type,
				session: params.session,
				ticket: (params.plan && params.plan.className) === 'Ticket' ? params.plan : undefined,
				membership: (params.plan && params.plan.className) === 'Membership' ? params.plan : undefined,
				product: params.product ? params.product : undefined,
				is_ticket: (params.plan && params.plan.className) === 'Ticket',
				is_membership: (params.plan && params.plan.className) === 'Membership',
				price: params.price,
				buyer: params.student,
				teacher: Parse.User.current(),
				received_by: params.received_by,
				in_israel: Parse.User.current().get('bank_country') === 'Israel',
				currency: this.getCurrencyBySessionOrPlan(params.session, params.plan, params.product),
				partial_amount: params.partial_amount,
				checks_array: params.checks_array,
				...(params.membershipPaymentType === 1 && {
					membershipIteration: params.membershipIteration,
				}),
			}, {
				success: function (paymentRes) {
					log('payment create success', paymentRes);
					// log('paymentFieldName ', this.paymentFieldName);
					// var paymentFieldName = this.paymentFieldName;
					if (params.plan) {
						var paymentFieldName = config.ENV === 'development' ? 'payment_debug' : 'payment';

						log('params.membershipPaymentType', params.membershipPaymentType);
						if (params.membershipPaymentType == 1) {
							var payments = params.userPaymentPlan.get('payments') ? params.userPaymentPlan.get('payments') : [];
							payments.push(paymentRes);
							params.userPaymentPlan.set('payments', payments);
						} else {
							params.userPaymentPlan.set(paymentFieldName, paymentRes);

						}
						Plan.updateUserPaymentPlan(params.userPaymentPlan, {}, (err, res) => {
							log(err, res);
							if (err) {
								log(err);
								callback(err);
								return;
							}
							callback(null, paymentRes);
						});

						return;
					} else if (params.product) {
						var paymentFieldName = config.ENV === 'development' ? 'payment_debug' : 'payment';
						params.userPaymentProduct.set(paymentFieldName, paymentRes);
						Product.updateUserPaymentProduct(params.userPaymentProduct, {}, (err, res) => {
							log(err, res);
							if (err) {
								log(err);
								callback(err);
								return;
							}
							callback(null, paymentRes);
						});
						return;
					}

					callback(null, paymentRes);
				},
				error: function (error) {
					log('Failed to create object, with error code: ' + error.message);
					callback(error);
				}
			});
		} catch (e) {
			log(e);
		}
	}

	//You can send a payment object or UserPaymentPlan object
	getPaymentType(payment) {
		try {
			if (!payment) {
				return;
			}
			// log('getPaymentType', payment);
			// log('getPaymentType Class Name', payment.className);

			if (payment.className !== this.paymentClassName) {
				var userPaymentPlan = payment;
				if (userPaymentPlan.get(this.paymentFieldName)) {
					payment = userPaymentPlan.get(this.paymentFieldName);
				} else {
					let payments = userPaymentPlan.get('payments');
					if (payments && payments.length > 0) {
						// log('payments length', payments.length);
						var isInMonths = userPaymentPlan.get("membership").get("expiration_type") == 1;
						if (payments.length == parseInt(userPaymentPlan.get('membership').get('expiration_period') / (isInMonths ? 1 : 4)) && !userPaymentPlan.get('membership').get('renews')) {

							return paymentTypes.PAYMENTS_DONE;
						} else if (payments[0].get('membershipIteration')) {
							return paymentTypes.PAYMENTS;
						} else {
							return paymentTypes.AUTO_PAYMENTS;
						}
					} else {
						return -1;
					}
				}
			}

			var paymentType;

			if (payment.get("payme_transaction_id")) {
				paymentType = payment.get('payment_method_type') ? payment.get('payment_method_type') : paymentTypes.CREDIT_CARD;
			} else {
				if ((payment.get("partial_amount") && payment.get("partial_amount")) > 0) {
					if (payment.get('payment_method_type') != paymentTypes.TRIAL) {
						paymentType = paymentTypes.PARTIAL;
					} else {
						paymentType = paymentTypes.TRIAL;
					}
				} else {
					paymentType = payment.get('payment_method_type') ? payment.get('payment_method_type') : paymentTypes.CASH;
				}
			}

			// log('paymentMethodType', paymentType)
			return paymentType
		} catch (e) {
			log(e)
			return paymentTypes.CASH;
		}
	}


	removePaidPayment(params, callback) {
		log("removePaidPayment", params.payment);
		log("removePaidPayment", params.userPaymentPlan);
		log("removePaidPayment", params.userPaymentProduct);
		params.payment.destroy({
			success: () => {
				if (params.userPaymentPlan) {
					var paymentFieldName = config.ENV === 'development' ? 'payment_debug' : 'payment';

					log('params.membershipPaymentType', params.membershipPaymentType);
					if (params.membershipPaymentType === 1) {
						log('Entered here');
						var payments = params.userPaymentPlan.get('payments') ? params.userPaymentPlan.get('payments') : [];
						let indexToRemove = payments.findIndex(payment => payment.get('membershipIteration') === params.membershipIteration);
						log('indexToRemove', indexToRemove);
						payments.splice(indexToRemove, 1);
						params.userPaymentPlan.set('payments', payments);
					} else {
						params.userPaymentPlan.set(paymentFieldName, null);
					}
					Plan.updateUserPaymentPlan(params.userPaymentPlan, {}, (err, res) => {
						log(err, res);
						if (err) {
							log(err);
							callback(err);
							return;
						}
						callback(null);
					});
					return;
				} else if (params.userPaymentProduct) {
					var paymentFieldName = config.ENV === 'development' ? 'payment_debug' : 'payment';
					params.userPaymentProduct.set(paymentFieldName, null);
					Product.updateUserPaymentProduct(params.userPaymentProduct, {}, (err, res) => {
						log(err, res);
						if (err) {
							log(err);
							callback(err);
							return;
						}
						callback(null);
					});
					return;
				}

				callback(null);
			},
			error: (err) => {
				if (err.code === 101) //Object not found = doesn't exist
				{
					if (params.userPaymentPlan) {
						var paymentFieldName = config.ENV === 'development' ? 'payment_debug' : 'payment';
						params.userPaymentPlan.set(paymentFieldName, null);
						Plan.updateUserPaymentPlan(params.userPaymentPlan, {}, (err, res) => {
							log(err, res);
							if (err) {
								log(err);
								callback(err);
								return;
							}
							callback(null);
						});
						return;
					} else if (params.userPaymentProduct) {
						var paymentFieldName = config.ENV === 'development' ? 'payment_debug' : 'payment';
						params.userPaymentProduct.set(paymentFieldName, null);
						Product.updateUserPaymentProduct(params.userPaymentProduct, {}, (err, res) => {
							log(err, res);
							if (err) {
								log(err);
								callback(err);
								return;
							}
							callback(null);
						});
						return;
					}
				}
				callback(err);
			}
		});
	}

	getCurrencyBySessionOrPlan(session, plan, product) {
		try {
			var currency = (session ? session.get('currency') : plan ? plan.get('currency') : product.get('currency'));
			if (!currency) {
				currency = api.User.getCurrencyByBankCountry(Parse.User.current());
			}
			return currency;
		} catch (e) {
			return api.User.getCurrencyByBankCountry(Parse.User.current());
		}
	}

	isBankCountryEuropeanCountry(user) {
		log("isBankCountryEuropeanCountry", user)

		try {
			var europe = [
				'AD', 'AL', 'AT', 'AX', 'BA',
				'BE', 'BG', 'BY', 'CH', 'CZ',
				'DE', 'DK', 'EE', 'ES', 'FI',
				'FO', 'FR', 'GB', 'GG', 'GI',
				'GR', 'HR', 'HU', 'IE', 'IM',
				'IS', 'IT', 'JE', 'LI', 'LT',
				'LU', 'LV', 'MC', 'MD', 'ME',
				'MK', 'MT', 'NL', 'NO', 'PL',
				'PT', 'RO', 'RS', 'RU', 'SE',
				'SI', 'SJ', 'SK', 'SM', 'UA',
				'VA'];

			var bankCountry = user.get("bank_country");
			log("bankCountry", bankCountry)
			if (!bankCountry) {
				bankCountry = user.get("country");
				log("country", bankCountry)
			}

			if (!bankCountry) {
				return false;
			} else {
				var bankCountryCode = countries().getCode(bankCountry);
				log("bankCountryCode", bankCountryCode)
				var inEurope = europe.indexOf(bankCountryCode) > -1;
				log("inEurope", inEurope)
				return inEurope;
			}
		} catch (e) {
			return false;
		}
	}

}

export default new Payment();
