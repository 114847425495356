import config from '../config';
import Parse from 'parse';
import {I18n} from 'react-i18nify';
import { log } from './Log';

const SELLER_TEST = {
	seller_social_id: '9999999999',
	seller_email: 'random@paymeservice.com',
	seller_bank_code: 54,
	seller_bank_branch: 123,
	seller_bank_account_number: 123456,
	//
	seller_first_name: 'Banana',
	seller_last_name: 'Master',
	seller_gender: 0,
	seller_phone: '0540123456',
	seller_description: 'An online store which specializes in rubber ducks',
	seller_site_url: 'amazon.com',
	seller_inc: 0,
	seller_inc_code: 123456,
	// seller_birthdate: '02/08/1987',
	// seller_social_id_issued: '01/01/2000',
	seller_birthdate: new Date('1987-08-02T03:24:00'),
	seller_social_id_issued: new Date('2000-01-01T03:24:00')
}

const REGISTER_TEACHER_DEFAULT_PARAMS = {
	seller_person_business_type: 1319,
	market_fee: config.REMOTE_CONFIG.merchant_fee
}

const PAYME_SELLER_INC_TYPES = {
	PERSON: 0,
	BUSINESS: 2
}

const ISRAELI_BANK_CODES = I18n._translations[I18n._localeKey].bankNames;

var Payme = class Payme {
	constructor() {

		this.PAYME_SELLER_INC_TYPES = PAYME_SELLER_INC_TYPES;
		this.ISRAELI_BANK_CODES = ISRAELI_BANK_CODES;

		// TESTING ONLY!
		this.SELLER_TEST = SELLER_TEST;
	}

	hasUserRegistered() {
		return !!Parse.User.current().get('payme_seller_id');
	}

	getBaseParams() {
		var user = Parse.User.current();
		var baseParams = {}

		if (this.hasUserRegistered()) {
			baseParams.seller_payme_id = user.get('payme_seller_id')
		}

		return baseParams;
	}


	registerTeacher(params, callback) {
		params.userId = Parse.User.current().id;
		Parse.Cloud.run('payme.registerTeacher', {
			...this.getBaseParams(), ...params
		}, {
			success: (res) => {
				// Parse.User.current().fetch();

				//email sending is in the cloud code
				callback(null, res)
			},
			error: (err) => {
				callback(err)
			}
		})
	}

	updateSellerFiles(params, callback) {
		log(params)
		Parse.Cloud.run('payme.updateSellerFiles', {
			...this.getBaseParams(), ...params
		}, {
			success: (res) => {
				// Parse.User.current().fetch();
				callback(null, res)
			},
			error: (err) => {
				callback(err)
			}
		})
	}

	getSeller(callback) {
		Parse.Cloud.run('getSeller', {...this.getBaseParams()}, {
			success: (res) => {
				callback(null, res)
			},
			error: (err) => {
				callback(err)
			}
		})
	}

}

export default new Payme();
